function readCookieObj() {
  return new URLSearchParams(document.cookie.replaceAll('; ', '&'));
}

function writeCookieObj(cookieObj) {
  document.cookie = cookieObj.toString().replaceAll('&', '; ');
}

export function getCookie(name) {
  const cookieObj = readCookieObj();
  return cookieObj.get(name);
}

export function setCookie(name, value) {
  const cookieObj = readCookieObj();
  cookieObj.set(name, value);
  writeCookieObj(cookieObj);
}

export function deleteCookie(name) {
  const cookieObj = readCookieObj();
  cookieObj.delete(name);
  writeCookieObj(cookieObj);
}

export function hasCookie(name) {
  const cookieObj = readCookieObj();
  return cookieObj.has(name);
}
