import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { OidcSecure } from '@axa-fr/react-oidc';
import { Trans } from 'react-i18next';
import asyncLoadingComponent from './components/base/util/AsyncLoadingComponent.util';
import OidcProvider from './components/smart-portal/oidc/oidc-provider';
import { UserInfoProvider } from './config/portal-config';
import './App.css';

const AsyncCustomerPortal = asyncLoadingComponent(() =>
  import('./components/smart-portal/portal/portal-app/PortalApp')
);

function App() {
  return (
    <Suspense fallback={<div>Loading... </div>}>
      <OidcProvider>
        <Trans>
          <BrowserRouter>
            <Switch>
              <Route path='/portal'>
                <OidcSecure>
                  <UserInfoProvider>
                    <AsyncCustomerPortal />
                  </UserInfoProvider>
                </OidcSecure>
              </Route>
              <Route path='/abp-sso'>
                <OidcSecure>
                  <UserInfoProvider>
                    <AsyncCustomerPortal />
                  </UserInfoProvider>
                </OidcSecure>
              </Route>
              <Route path='/'>
                <Redirect to='/portal' />
              </Route>
            </Switch>
          </BrowserRouter>
        </Trans>
      </OidcProvider>
    </Suspense>
  );
}

export default App;
