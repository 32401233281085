import appStyles from '../style/_AppStyles.scss';
import allianzLogo from '../../../resources/images/branding-allianz.png';
import diuLogo from '../../../resources/images/branding-diu.png';
import ehTelesalesLogo from '../../../resources/images/branding-eh-telesales.png';
import firmoLogo from '../../../resources/images/branding-fimo.png';
import ggwLogo from '../../../resources/images/branding-ggw.png';
import staticConfiguration from '../../../resources/config/staticConfiguration.json';

const brandingLogo = {
  allianz: allianzLogo,
  diu: diuLogo,
  'eh-telesales': ehTelesalesLogo,
  firmo: firmoLogo,
  ggw: ggwLogo
};

const currentBranding = {
  text: {
    name: 'default'
  },
  colors: {
    'brand-primary': appStyles.brandPrimary,
    'brand-secondary': appStyles.brandSecondary
  },
  logos: {
    toolbar: allianzLogo
  },
  links: {
    product1: 'https://stage.eh-smart-portal.de/downloads/eh-avalkredit-smart-broschuere.pdf'
  },
  features: {
    customerId: false
  }
};

export const setIntermediaryBranding = (intermediary) => {
  console.log('Branding configuration for intermediary: ', intermediary);
  const intermediaryBranding = staticConfiguration.intermediaryBranding.find((branding) => {
    return branding.brandingName === (intermediary?.brandingName ?? 'allianz');
  });

  if (intermediaryBranding) {
    currentBranding.colors['brand-primary'] = intermediaryBranding.primaryColor;
    currentBranding.colors['brand-secondary'] = intermediaryBranding.secondaryColor;
    currentBranding.logos.toolbar = brandingLogo[intermediaryBranding.logoName];
  }
};

export const getBrandingColor = (color) =>
  currentBranding.colors.hasOwnProperty(color) ? currentBranding.colors[color] : appStyles.text;

export const getBrandingLogo = (logoName) =>
  currentBranding.logos.hasOwnProperty(logoName) ? currentBranding.logos[logoName] : undefined;

export const getBrandingLink = (linkName) =>
  currentBranding.links.hasOwnProperty(linkName) ? currentBranding.links[linkName] : '';

export const hasBrandingFeature = (featureName) =>
  currentBranding.features.hasOwnProperty(featureName) ? currentBranding.features[featureName] : false;

export const setBranding = (newBranding) => {
  for (const segment in currentBranding)
    if (newBranding.hasOwnProperty(segment)) {
      currentBranding[segment] = {
        ...currentBranding[segment],
        ...newBranding[segment]
      };
    }
};
