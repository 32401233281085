import { get } from './api-helper';

const pdfUrl = `${process.env.REACT_APP_SPN_PDF_API}/statics`;

export const getStaticPdf = async (staticPdfCode) => {
  try {
    const response = await get(`${pdfUrl}/${staticPdfCode}`);
    return response.headers.get('location');
  } catch (e) {
    console.error(`${staticPdfCode} pdf is unavailable`, e);
    return undefined;
  }
};
