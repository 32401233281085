import {
  fetchListForParameter,
  fetchResponseForParameter,
  patchContent,
  postContent,
  putContent,
  deleteUrl
} from './api-helper';

// const policiesUrl = (product) => `/${API_PREFIX}/${API_SERVICE}/${product}/${API_VERSION}/policies`;
const policiesUrl = (product) => `${process.env.REACT_APP_ORCHESTRATION_API}/${product}/policies`;
const policiesIdUrl = (product, policyId) => policiesUrl(product) + `/${policyId}`;

export const getPolicies = async (product, parameter, page, pageSize, sort) => {
  const getPoliciesUrl = policiesUrl(product);
  return fetchListForParameter(getPoliciesUrl, parameter, page, pageSize, sort);
};

export const getPolicyById = async (product, policyId) => {
  const policyByIdUrl = policiesIdUrl(product, policyId);
  return fetchResponseForParameter(policyByIdUrl, null);
};

export const putPolicyById = async (product, policyId, policy) => {
  const policyByIdUrl = policiesIdUrl(product, policyId);
  return await putContent(policyByIdUrl, policy);
};

export const patchPolicyById = async (product, policyId, policy) => {
  const policyByIdUrl = policiesIdUrl(product, policyId);
  return await patchContent(policyByIdUrl, policy);
};

export const postPolicy = async (product, company, gradingJobUrl) => {
  const postPolicyUrl = policiesUrl(product);
  const newPolicy = {
    productCode: product,
    company: company,
    gradingJobUrl: gradingJobUrl
  };
  return postContent(postPolicyUrl, newPolicy);
};

export const postPolicyChange = async (product, policyId) => {
  const postPolicyUrl = policiesUrl(product);
  const newPolicy = { productCode: product, existingPolicyId: policyId };
  return postContent(postPolicyUrl, newPolicy);
};

export const deletePolicyBySequenceId = async (sequenceId) => {
  return deleteUrl(`${process.env.REACT_APP_ORCHESTRATION_API}/policies/idSequence/${sequenceId}`);
};

export const patchPolicyBySequenceId = async (sequenceId, policy) => {
  return patchContent(`${process.env.REACT_APP_ORCHESTRATION_API}/policies/idSequence/${sequenceId}`, policy);
};

export const getUploadSignedUrl = async (product, policyId, attachmentType) => {
  const getUploadUrl = policiesIdUrl(product, policyId) + `/${attachmentType}/upload`;
  return fetchResponseForParameter(getUploadUrl, null);
};

export const getDownloadSignedUrl = async (product, policyId, attachmentType) => {
  const getDownloadUrl = policiesIdUrl(product, policyId) + `/${attachmentType}/download`;
  return fetchResponseForParameter(getDownloadUrl, null);
};
