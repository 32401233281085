import Color from 'color';

import { getBrandingColor } from '../util/Branding.util';
import appStyles from './_AppStyles.module.scss';

export const Sizes = ['smallest', 'smaller', 'small', 'normal', 'large', 'largest'];
export const FillStyles = ['none', 'outline', 'solid'];
export const TextStyles = ['none', 'strong', 'italic', 'italic-strong'];
export const TextElements = ['h1', 'h2', 'h3', 'h5', 'h6', 'div', 'span'];
export const AppColors = [
  'none',
  'canvas',
  'text',
  'highlight-text',
  'inverted-text',
  'divider',
  'primary',
  'secondary',
  'disabled',
  'warning',
  'invalid',
  'placeholder',
  'dimmed',
  'dark',
  'backdrop',
  'brand-primary',
  'brand-accent',
  'state-draft',
  'state-unsigned',
  'state-signed',
  'state-sent',
  'state-approved',
  'state-rejected',
  'state-terminated',
  'state-cancelled'
];

export const States = ['normal', 'focus', 'hover', 'disabled'];
export const LabelPositions = ['top', 'left'];
export const Orientations = ['horizontal', 'vertical'];

export const AppColorMapping = {
  none: appStyles.transparent,
  canvas: appStyles.canvas,
  text: appStyles.text,
  'highlight-text': appStyles.highlightText,
  'inverted-text': appStyles.invertedText,
  primary: appStyles.primary,
  divider: appStyles.divider,
  secondary: appStyles.secondary,
  disabled: appStyles.disabled,
  warning: appStyles.warning,
  invalid: appStyles.invalid,
  placeholder: appStyles.placeholder,
  dimmed: appStyles.dimmed,
  dark: appStyles.dark,
  backdrop: appStyles.backdrop,
  'state-draft': appStyles.stateDraft,
  'state-unsigned': appStyles.stateUnsigned,
  'state-signed': appStyles.stateSigned,
  'state-sent': appStyles.stateSent,
  'state-approved': appStyles.stateApproved,
  'state-rejected': appStyles.stateRejected,
  'state-terminated': appStyles.stateTerminated,
  'state-cancelled': appStyles.stateCancelled
};

export const AppMatchingElementColorMapping = {
  none: 'text',
  canvas: 'text',
  text: 'inverted-text',
  'highlight-text': 'inverted-text',
  'inverted-text': 'text',
  primary: 'inverted-text',
  divider: 'inverted-text',
  secondary: 'inverted-text',
  disabled: 'inverted-text',
  warning: 'inverted-text',
  invalid: 'inverted-text',
  placeholder: 'inverted-text',
  dimmed: 'text',
  dark: 'inverted-text',
  backdrop: 'inverted-text',
  'brand-primary': 'inverted-text',
  'brand-accent': 'inverted-text',
  'state-draft': 'inverted-text',
  'state-unsigned': 'inverted-text',
  'state-signed': 'inverted-text',
  'state-sent': 'inverted-text',
  'state-approved': 'inverted-text',
  'state-rejected': 'inverted-text',
  'state-terminated': 'inverted-text',
  'state-cancelled': 'inverted-text'
};

export const getAppColor = (colorName, forState = 'normal') => {
  const colorValue = AppColorMapping.hasOwnProperty(colorName)
    ? AppColorMapping[colorName]
    : getBrandingColor(colorName);
  return forState === 'hover' ? Color(colorValue).darken(0.2) : colorValue;
};

export const getMatchingElementColor = (colorName) => {
  return AppMatchingElementColorMapping.hasOwnProperty(colorName) ? AppMatchingElementColorMapping[colorName] : 'text';
};

export const getFillableElementStyle = (
  fillStyle,
  elementColor,
  contentColor = 'none',
  borderColor = 'none',
  fillColor = 'none',
  state = 'normal'
) => {
  if (state === 'disabled') contentColor = 'disabled';
  if (fillStyle === 'solid') {
    if (fillColor === 'none') {
      fillColor = elementColor;
      if (contentColor !== 'disabled') {
        contentColor = getMatchingElementColor(fillColor);
      }
    }
    if (contentColor === 'none' || contentColor === elementColor) {
      contentColor = fillColor === elementColor ? getMatchingElementColor(fillColor) : elementColor;
    }
    if (borderColor === 'none') {
      borderColor = fillColor;
    }
  } else {
    if (contentColor === 'none') contentColor = elementColor;
    if (borderColor === 'none') {
      borderColor = elementColor;
    }
  }
  return !fillColor || fillColor === 'canvas' || fillColor === 'none'
    ? {
        color: getAppColor(contentColor),
        background: 'none',
        borderColor: getAppColor(borderColor, state)
      }
    : {
        color: getAppColor(contentColor),
        backgroundColor: fillStyle === 'solid' ? getAppColor(fillColor, state) : 'transparent',
        borderColor: getAppColor(borderColor, state)
      };
};

export const getFontStyleClass = (textStyle = 'none') => {
  const strongFont = textStyle && textStyle.indexOf('strong') >= 0;
  const italicFont = textStyle && textStyle.indexOf('italic') >= 0;
  return strongFont
    ? italicFont
      ? 'font-family-bold-italic'
      : 'font-family-bold'
    : italicFont
    ? 'font-family-italic'
    : 'font-family-regular';
};

export const getTextElementStyle = (size, textStyle, textColor) => {
  let textSize;
  switch (size) {
    case 'smallest':
      textSize = '30%';
      break;
    case 'smaller':
      textSize = '60%';
      break;
    case 'small':
      textSize = '75%';
      break;
    case 'large':
      textSize = '150%';
      break;
    case 'larger':
      textSize = '200%';
      break;
    case 'largest':
      textSize = '300%';
      break;
    default:
      textSize = '100%';
  }
  const strongFont = textStyle && textStyle.indexOf('strong') >= 0;
  const italicFont = textStyle && textStyle.indexOf('italic') >= 0;
  return {
    color: getAppColor(textColor),
    fontWeight: strongFont ? 'bold' : 'normal',
    fontStyle: italicFont ? 'italic' : 'normal',
    fontSize: textSize
  };
};
