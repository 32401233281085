import { companySearch } from './company-search-api';
import { getStaticPdf } from './pdf-api';
import {
  deletePolicyBySequenceId,
  getDownloadSignedUrl,
  getPolicies,
  getPolicyById,
  getUploadSignedUrl,
  patchPolicyById,
  patchPolicyBySequenceId,
  postPolicy,
  postPolicyChange,
  putPolicyById
} from './policy-api';
import { getUserInfo } from './user-api';
import { getRadarLivePricing } from './radar-live-api';
import { allianzConsumerData } from './allianz-api';
import { getActiveAlerts } from './alerting-api';

let apiCallVector = {
  companySearch: companySearch,
  getStaticPdf: getStaticPdf,
  getPolicies: getPolicies,
  getPolicyById: getPolicyById,
  putPolicyById: putPolicyById,
  patchPolicyById: patchPolicyById,
  postPolicy: postPolicy,
  postPolicyChange: postPolicyChange,
  deletePolicyBySequenceId: deletePolicyBySequenceId,
  patchPolicyBySequenceId: patchPolicyBySequenceId,
  getUploadSignedUrl: getUploadSignedUrl,
  getDownloadSignedUrl: getDownloadSignedUrl,
  getUserInfo: getUserInfo,
  getRadarLivePricing: getRadarLivePricing,
  getActiveAlerts: getActiveAlerts,
  allianzConsumerData: allianzConsumerData
};

export const setApiCallVector = (vector) => {
  apiCallVector = vector;
};

export const apiCalls = {
  companySearch: (product, search, page, pageSize) => {
    return apiCallVector.companySearch(search, page, pageSize);
  },
  companyGrades: (product, companyId) => {
    return apiCallVector.companyGrades(companyId);
  },
  getStaticPdf: async (staticPdfCode) => {
    return apiCallVector.getStaticPdf(staticPdfCode);
  },
  getPoliciesUnfiltered: (product, parameter, page, pageSize, sort) => {
    return apiCallVector.getPolicies(product, parameter, page, pageSize, sort);
  },
  getPolicies: (product, parameter, page, pageSize, sort) => {
    return apiCallVector.getPolicies(product, parameter, page, pageSize, sort);
  },
  getPolicyById: (product, policyId) => {
    return apiCallVector.getPolicyById(product, policyId);
  },
  putPolicyById: (product, policyId, policy) => {
    return apiCallVector.putPolicyById(product, policyId, policy);
  },
  patchPolicyById: (product, policyId, policy) => {
    return apiCallVector.patchPolicyById(product, policyId, policy);
  },
  postPolicy: (product, company, gradingJobUrl) => {
    return apiCallVector.postPolicy(product, company, gradingJobUrl);
  },
  postPolicyChange: (product, policyId) => {
    return apiCallVector.postPolicyChange(product, policyId);
  },
  deletePolicyBySequenceId: async (idSequence) => {
    return await apiCallVector.deletePolicyBySequenceId(idSequence);
  },
  patchPolicyBySequenceId: async (idSequence, policy) => {
    return await apiCallVector.patchPolicyBySequenceId(idSequence, policy);
  },
  getUploadSignedUrl: (product, policyId, attachmentType) => {
    return apiCallVector.getUploadSignedUrl(product, policyId, attachmentType);
  },
  getDownloadSignedUrl: (product, policyId, attachmentType) => {
    return apiCallVector.getDownloadSignedUrl(product, policyId, attachmentType);
  },
  getUserInfo: () => {
    return apiCallVector.getUserInfo();
  },
  getRadarLivePricing: (parameter) => {
    return apiCallVector.getRadarLivePricing(parameter);
  },
  getActiveAlerts: () => {
    return apiCallVector.getActiveAlerts();
  },
  allianzConsumerData: (perId) => {
    return apiCallVector.allianzConsumerData(perId);
  }
};
