import React from 'react';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import sanitizeHTML from 'sanitize-html';

// const  navigatorLanguage = navigator.language || navigator.userLanguage;
export const browserLanguage = 'de'; // SPN-758 navigatorLanguage.substring(0, 2) || 'de';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(XHR)
  .init({
    whitelist: ['en', 'de'],
    lng: browserLanguage,
    fallbackLng: browserLanguage,
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/components/{{lng}}.json'
    }
  });

export function translationTextExists(tKey) {
  return i18n.exists(tKey) && typeof i18n.t(tKey, { returnObjects: true }) === 'string';
}

export function translate(tKey) {
  return tKey && i18n.exists(tKey) ? i18n.t(tKey) : undefined;
}

export function addLanguageBundle(language, bundle) {
  i18n.addResourceBundle(language, 'translation', bundle, true, true);
}

export function addLocaleBundle(bundle) {
  if ('default' in bundle) {
    addLocaleBundle(bundle.default); // ???
  } else {
    for (const language in bundle)
      if (bundle.hasOwnProperty(language)) {
        addLanguageBundle(language, bundle[language]);
      }
  }
}

export default class TranslateUtil {
  constructor(tKey) {
    this.tKey = tKey || '';
  }

  hasLocaleText(elementName) {
    return translationTextExists(this.tKey + '.' + elementName) || translationTextExists(this.tKey + '-' + elementName);
  }

  getLocaleText(elementName) {
    return translationTextExists(this.tKey + '-' + elementName)
      ? i18n.t(this.tKey + '-' + elementName)
      : i18n.t(this.tKey + '.' + elementName);
  }

  get hasTranslation() {
    return translationTextExists(this.tKey) || this.hasLabel;
  }

  get translation() {
    return this.hasLabel ? this.label : i18n.exists(this.tKey) ? i18n.t(this.tKey) : this.tKey;
  }

  get hasLabel() {
    return this.hasLocaleText('label');
  }

  get label() {
    return this.getLocaleText('label');
  }

  get hasPlaceholder() {
    return this.hasLocaleText('placeholder');
  }

  get placeholder() {
    return this.getLocaleText('placeholder');
  }

  get hasTooltip() {
    return this.hasLocaleText('tooltip');
  }

  get tooltip() {
    return this.getLocaleText('tooltip');
  }

  get hasInfoText() {
    return this.hasLocaleText('info');
  }

  get infoText() {
    return this.getLocaleText('info');
  }

  get hasInvalidText() {
    return this.hasLocaleText('invalid');
  }

  get invalidText() {
    return this.getLocaleText('invalid');
  }

  get __hasHtml() {
    return this.hasLocaleText('html');
  }

  get __html() {
    return this.getLocaleText('html');
  }

  replaceText(text, replaceText) {
    let result = text;
    if (typeof result === 'string' && replaceText) {
      for (const alias in replaceText) {
        result = result.split(alias).join(replaceText[alias]);
      }
    }
    return result;
  }

  getContent(properties) {
    const { children, replaceText, ...restProperties } = properties;
    return children ? (
      <span {...restProperties}> {children} </span>
    ) : this.__hasHtml ? (
      <span
        {...restProperties}
        dangerouslySetInnerHTML={{
          __html: this.replaceText(sanitizeHTML(this.__html), replaceText)
        }}
      />
    ) : (
      <span {...restProperties}>{this.replaceText(this.translation, replaceText)} </span>
    );
  }
}
