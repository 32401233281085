import { OidcProvider as AxaOidcProvider } from '@axa-fr/react-oidc';
import { Oidc } from '@axa-fr/react-oidc/dist/vanilla';
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import WaitModal from '../../base/dialog/wait-modal/WaitModal';
import AuthenticatingErrorModal from './authenticating-error-modal/authenticating-error-modal';

const configuration = {
  authority: process.env.REACT_APP_IDP_ISSUER,
  client_id: process.env.REACT_APP_IDP_CLIENT_ID_EXT,
  redirect_uri: window.location.origin + '/login',
  scope: `openid spn-portal-extern-${uuidv4()} offline_access`
};

const allianzConfiguration = {
  authority: process.env.REACT_APP_IDP_ISSUER,
  client_id: process.env.REACT_APP_IDP_CLIENT_ID_AZ,
  redirect_uri: window.location.origin + '/cim/login',
  scope: `openid spn-portal-allianz-${uuidv4()} offline_access`
};

const OidcLoadingComponent = () => <WaitModal text='Smart Portal' closeButton={false} />;

const SessionLostComponent = () => {
  useEffect(async () => {
    await Oidc.get().destroyAsync();
    document.location.reload();
  }, []);

  return <OidcLoadingComponent />;
};

export default function OidcProvider({ children }) {
  const [allianz, setAllianz] = useState(false);

  useEffect(() => {
    if (allianz) {
      localStorage.setItem('allianz', 'true');
    } else {
      localStorage.removeItem('allianz');
    }
  }, [allianz]);

  useEffect(() => {
    const {
      location: { pathname }
    } = document;

    setAllianz(pathname.startsWith('/cim/login') || pathname.startsWith('/abp-sso') || localStorage.getItem('allianz'));
  }, []);

  useEffect(() => {
    if (document.location.pathname.startsWith('/login')) {
      setAllianz(false);
    }
  }, [document.location.pathname]);

  return (
    <AxaOidcProvider
      configuration={allianz ? allianzConfiguration : configuration}
      loadingComponent={OidcLoadingComponent}
      authenticatingComponent={OidcLoadingComponent}
      callbackSuccessComponent={OidcLoadingComponent}
      sessionLostComponent={SessionLostComponent}
      authenticatingErrorComponent={AuthenticatingErrorModal}
      callbackErrorComponent={AuthenticatingErrorModal}
    >
      {children}
    </AxaOidcProvider>
  );
}
